import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import downloadArrow from "../../../assets/img/download-arrow.png";
import ReactToPdf from "react-to-pdf";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/ar";
import { Button, CardContent, Skeleton } from "@mui/material";
import ChartArea from "../../../components/ChartArea";
import {
  averageSmileIcon,
  badSmileIcon,
  goodSmileIcon,
  veryBadSmileIcon,
  veryGoodSmileIcon,
} from "../../../assets";
import {
  getAverageMessagesPerSession,
  getAverageSessionDuration,
  getUserFeedbackRate,
} from "../../../slices/analytics";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import { toFixedTwo } from "../../../utilities/toFixedTwo";
function AiStats() {
  const { t } = useTranslation();
  moment.locale(localStorage.getItem("i18nextLng"));
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const ref = React.createRef();
  const options = {
    orientation: "landscape",
    unit: "in",
    format: [14, 15],
  };
  const {
    averageSessionDuration,
    totalAverageDuration,
    statusAverageSessionDuration,
    averageMessagesPerSession,
    totalAverageMessages,
    statusAverageMessagesPerSession,
    usersFeedbackRate,
    statusUsersfeedbackRate,
    averageUsersFeedbackPercentage,
    statusBotResponseTime,
  } = useSelector((state) => state.analytics);

  const ratings = {
    veryGoodRate: usersFeedbackRate?.find((el) => el?.rating === 5)
      ? usersFeedbackRate?.find((el) => el?.rating === 5)?.percentage
      : 0,
    goodRate: usersFeedbackRate?.find((el) => el?.rating === 4)
      ? usersFeedbackRate?.find((el) => el?.rating === 4)?.percentage
      : 0,
    averageRate: usersFeedbackRate?.find((el) => el?.rating === 3)
      ? usersFeedbackRate?.find((el) => el?.rating === 3)?.percentage
      : 0,
    badRate: usersFeedbackRate?.find((el) => el?.rating === 2)
      ? usersFeedbackRate?.find((el) => el?.rating === 2)?.percentage
      : 0,
    veryBadRate: usersFeedbackRate?.find((el) => el?.rating === 1)
      ? usersFeedbackRate?.find((el) => el?.rating === 1)?.percentage
      : 0,
  };

  const [progressPercent, setProgressPercent] = useState({
    veryGoodRate: 0,
    goodRate: 0,
    averageRate: 0,
    badRate: 0,
    veryBadRate: 0,
  });

  useEffect(() => {
    // Calculate the progress percentage based on data changes
    const newProgressPercent = {
      veryGoodRate: ratings.veryGoodRate,
      goodRate: ratings.goodRate,
      averageRate: ratings.averageRate,
      badRate: ratings.badRate,
      veryBadRate: ratings.veryBadRate,
    };
    //const newProgressPercent = 100;
    setProgressPercent(newProgressPercent);
  }, [usersFeedbackRate, statusBotResponseTime]);

  useEffect(() => {
    statusUsersfeedbackRate === "success" &&
      // Move the progress bar whenever the progress percentage changes
      moveProgressBar();

    // Add a window resize event listener to handle progress bar on resize
    window.addEventListener("resize", moveProgressBar);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", moveProgressBar);
    };
  }, [progressPercent, statusUsersfeedbackRate]);

  // SIGNATURE PROGRESS
  const moveProgressBar = () => {
    Object.entries(progressPercent).forEach((el, index) => {
      const getPercent = progressPercent[el[0]] / 100;
      const getProgressWrapWidth = document.querySelector(
        `.progress-wrap-${index + 1}`
      )?.clientWidth;
      const progressTotal = getPercent * getProgressWrapWidth;
      const animationLength = 1000;

      const progressBar = document.querySelector(`.progress-bar-${index + 1}`);

      if (progressBar) {
        // Animate the progress bar
        document.querySelector(
          `.progress-bar-${index + 1}`
        ).style.transition = `left ${animationLength}ms ease-in-out`;
        document.querySelector(
          `.progress-bar-${index + 1}`
        ).style.left = `${progressTotal}px`;
      }
    });
  };

  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(new Date());

  useEffect(() => {
    !Array.isArray(user) &&
      (statusAverageSessionDuration !== "success" &&
        dispatch(
          getAverageSessionDuration({ websiteID: user?.websiteID, year, month })
        ),
      statusAverageMessagesPerSession !== "success" &&
        dispatch(
          getAverageMessagesPerSession({
            websiteID: user?.websiteID,
            year,
            month,
          })
        ),
      statusUsersfeedbackRate !== "success" &&
        dispatch(
          getUserFeedbackRate({ websiteID: user?.websiteID, year, month })
        ).then(
          (res) =>
            !res?.error &&
            (() => {
              // on page load...
              moveProgressBar();
            })()
        ));
  }, [user, year, month]);

  return (
    <div className="analytics-container-ai">
      <div className="title--analytics--page-ai">
        <div>
          <p className="analytics--p-ai">
            {t("Analytics").replace(" ", "\xa0")}
          </p>
        </div>
        <div className="btns-container-analytics-ai">
          <div>
            {" "}
            <Button
              disabled={month === null}
              onClick={() => setMonth(null)}
              className="reset-month-btn"
            >
              {t("Reset month")}
            </Button>
          </div>
          <div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <div
                style={{ display: "flex", alignItems: "center", gap: "15px" }}
              >
                <DatePicker
                  views={["month"]}
                  disableFuture
                  slotProps={{
                    calendarHeader: {
                      sx: { display: "none" },
                    },
                  }}
                  value={month}
                  onChange={(newValue) => setMonth(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                />
                <DatePicker
                  views={["year"]}
                  disableFuture
                  minDate={new Date("2024")}
                  value={year}
                  onChange={(newValue) => setYear(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </LocalizationProvider>
          </div>

          <ReactToPdf
            targetRef={ref}
            filename="div-blue.pdf"
            options={options}
            x={1}
            y={1}
            scale={0.7}
          >
            {({ toPdf }) => (
              <div onClick={toPdf} className="download-btn-ai">
                <div>
                  <LazyLoadImage
                    src={downloadArrow}
                    alt="arrow"
                    className="download-arrow"
                  />
                </div>

                <button className="btn-report-ai">
                  {t("Download Report")}
                </button>
              </div>
            )}
          </ReactToPdf>
        </div>
      </div>
      <div ref={ref}>
        {" "}
        <div className="stat-wrapper-ai">
          <div
            style={{
              display: "flex",
              width: "100%",
              gap:
                (["idle", "loading"].includes(
                  statusAverageMessagesPerSession
                ) ||
                  ["idle", "loading"].includes(statusAverageSessionDuration)) &&
                "15px",
              padding:
                (["idle", "loading"].includes(
                  statusAverageMessagesPerSession
                ) ||
                  ["idle", "loading"].includes(statusAverageSessionDuration)) &&
                "0 12px",
            }}
          >
            {["idle", "loading"].includes(statusAverageMessagesPerSession) ? (
              <Skeleton
                width={"49%"}
                sx={{ minHeight: "400px", height: "100%" }}
              />
            ) : (
              <div className="stat-container-ai">
                <div>
                  <h2 className="stat-title-ai">
                    {t("Nombre moyen de messages par session")} :{" "}
                    <span>{toFixedTwo(totalAverageMessages)}</span>
                  </h2>
                </div>
                <CardContent style={{ padding: 0 }}>
                  <ChartArea
                    month={month}
                    averageMessagesPerSession={averageMessagesPerSession}
                  />
                </CardContent>
              </div>
            )}

            {["idle", "loading"].includes(statusAverageSessionDuration) ? (
              <Skeleton
                width={"49%"}
                sx={{ minHeight: "400px", height: "100%" }}
              />
            ) : (
              <div className="stat-container-ai">
                <div>
                  <h2 className="stat-title-ai">
                    {t("Durée moyenne par session")}:{" "}
                    <span>
                      {toFixedTwo(totalAverageDuration / 60)} {t("mins")}
                    </span>
                  </h2>
                </div>
                <CardContent style={{ padding: 0 }}>
                  <ChartArea
                    averageSessionDuration={averageSessionDuration}
                    month={month}
                  />
                </CardContent>
              </div>
            )}
          </div>
        </div>
        <div className="stat-wrapper-ai" style={{ flexWrap: "nowrap" }}>
          {["idle", "loading"].includes(statusUsersfeedbackRate) ? (
            <div style={{ width: "50%", padding: "0 12px" }}>
              <Skeleton
                width={"100%"}
                sx={{ minHeight: "400px", height: "100%" }}
              />
            </div>
          ) : (
            <div className="stat-emojis">
              <div className="stat-emojis-title-container">
                <h2 className="stat-emojis-title">
                  {t("Taux de satisfaction utilisateur")} <br />
                </h2>
                <span>{toFixedTwo(averageUsersFeedbackPercentage)}%</span> 
              </div>
              <div className="stat-specific-emojis-wrapper">
                <div className="stat-progress">
                  <div className="stat-specific-emoji">
                    <div>
                      <LazyLoadImage src={veryGoodSmileIcon} />
                      <span>{t("Very good")}</span>
                    </div>
                    <span className="stat-emoji-percentage">
                      {ratings.veryGoodRate}%
                    </span>
                  </div>

                  <div
                    className="progress-wrap-1 progress"
                    data-progress-percent={(ratings.veryGoodRate / 5) * 100}
                  >
                    <div class="progress-bar-1 progress"></div>
                  </div>
                </div>

                <div className="stat-progress">
                  <div className="stat-specific-emoji">
                    <div>
                      <LazyLoadImage src={goodSmileIcon} />
                      <span>{t("Good")}</span>
                    </div>
                    <span className="stat-emoji-percentage">
                      {ratings.goodRate}%
                    </span>
                  </div>

                  <div
                    className="progress-wrap-2 progress"
                    data-progress-percent={(ratings.goodRate / 5) * 100}
                  >
                    <div class="progress-bar-2 progress"></div>
                  </div>
                </div>
                <div className="stat-progress">
                  <div className="stat-specific-emoji">
                    <div>
                      <LazyLoadImage src={averageSmileIcon} />
                      <span>{t("Average")}</span>
                    </div>
                    <span className="stat-emoji-percentage">
                      {ratings.averageRate}%
                    </span>
                  </div>

                  <div
                    className="progress-wrap-3 progress"
                    data-progress-percent={(ratings.averageRate / 5) * 100}
                  >
                    <div class="progress-bar-3 progress"></div>
                  </div>
                </div>
                <div className="stat-progress">
                  <div className="stat-specific-emoji">
                    <div>
                      <LazyLoadImage src={badSmileIcon} />
                      <span>{t("Bad")}</span>
                    </div>
                    <span className="stat-emoji-percentage">
                      {ratings.badRate}%
                    </span>
                  </div>

                  <div
                    className="progress-wrap-4 progress"
                    data-progress-percent={(ratings.badRate / 5) * 100}
                  >
                    <div class="progress-bar-4 progress"></div>
                  </div>
                </div>
                <div className="stat-progress">
                  <div className="stat-specific-emoji">
                    <div>
                      <LazyLoadImage src={veryBadSmileIcon} />
                      <span>{t("Very bad")}</span>
                    </div>
                    <span className="stat-emoji-percentage">
                      {ratings.veryBadRate}%
                    </span>
                  </div>

                  <div
                    className="progress-wrap-5 progress"
                    data-progress-percent={(ratings.veryBadRate / 5) * 100}
                  >
                    <div class="progress-bar-5 progress"></div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AiStats;
